
    @import "config.scss";
 
.container {
  position: relative;
  align-items: center;
  background-color: white;
  border-radius: var(--br-hard) var(--br-hard) 0 0;
  height: clamp(18rem, 95vw, 35rem);

  @include for-desktop-up {
    width: 50%;
    border-radius: var(--br-hard) 0 0 var(--br-hard);
    height: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }
}

.slider {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  transition: scroll 1s;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  overflow-y: hidden;
  border-radius: var(--br-hard) var(--br-hard) 0 0;
  justify-content: center;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  @include for-desktop-up {
    min-width: 422px;

    border-radius: var(--br-hard) 0 0 var(--br-hard);
  }
}

.sliderContainer {
  display: flex;
  height: 100%;
}

.bannerWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  flex: 0 0 auto;
}

.logos {
  z-index: var(--z-content);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoImg {
  display: flex;
  justify-content: center;
  height: 25px;
  width: 47vw;

  img {
    height: 100%;
    width: 100%;
  }
}

.img {
  height: 100%;
  width: 100%;
  // max-height: 400px;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.favorite {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 9;
  transform: scale(0.7);
  width: 2.5rem;
}

.zoom {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 9;
  transform: scale(0.7);
  width: 2.5rem;
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  appearance: none;
  border: none;
  background: transparent;
  padding: 1rem;
  z-index: 111111;
}
