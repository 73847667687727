
    @import "config.scss";
 
.wrapper {
  margin: 0 20px;
  padding-top: 20px;

  @include for-desktop-up {
    display: flex;
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 25px;

  a {
    color: var(--color-primary);
  }

  h1 {
    padding-bottom: 35px;
  }

  p {
    padding-bottom: 20px;
  }
}

.select {
  @include for-desktop-up {
    display: none;
  }
}

.divider {
  display: none;

  @include for-desktop-up {
    display: block;
    margin-inline: 20px;
  }
}

.sidebar {
  display: none;
  width: 250px;
  height: fit-content;

  @include for-desktop-up {
    display: flex;
    margin-left: 35px;
  }

  .faqItem {
    display: block;
    color: white;
    padding: 15px 12px;

    &--active {
      font-weight: 700;
      color: var(--color-primary);
    }
  }

  p,
  strong,
  li {
    margin-bottom: 10px;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    line-height: 1.3rem;
  }

  strong {
    font-weight: 700;
  }

  ul {
    list-style: unset;
    width: 100%;
  }
}

.section {
  color: white;
  background-color: var(--color-primary);
  border: solid var(--color-primary) 1px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;

  // ::-webkit-scrollbar {
  //   width: 2px;
  // }

  // ::-webkit-scrollbar-track {
  //   margin-top: 20px;
  //   margin-bottom: 20px;
  // }

  // ::-webkit-scrollbar-thumb {
  //   background-color: var(--color-primary);
  // }
}

.infoSection {
  background-color: var(--color-grey);
  border-radius: 15px;
  width: 70%;
  overflow-y: scroll;
  padding: 20px;
}

.faqs {
  width: 30%;
}
