
    @import "config.scss";
 
.img {
  position: relative;
  z-index: var(--z-content);

  img {
    position: relative;
    height: 100%;
  }
}
