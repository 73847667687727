
    @import "config.scss";
 
.space {
  display: none;

  @include for-desktop-up {
    display: block;
  }
}
