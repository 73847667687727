
    @import "config.scss";
 
.wrapper {
  background-color: var(--color-primary);
}

.limitedWrapper {
  padding-block: 27px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;

  @include for-desktop-up {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.divider {
  display: none;

  @include for-desktop-up {
    display: flex;
  }
}

.info {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 1rem;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;

  @include for-desktop-up {
    padding-inline: 0;
    width: 50%;
  }
}

.section {
  width: fit-content;
  white-space: nowrap;
}

.active:hover {
  text-decoration: underline;
}

.logos {
  display: flex;
  gap: 15px;
  align-items: center;

  p {
    display: none;
  }

  @include for-desktop-up {
    margin-top: 24px;
    align-items: center;

    p {
      display: block;
    }
  }
}

.secondLine {
  display: flex;
  row-gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  .phoneLogo {
    display: none;
  }

  @include for-desktop-up {
    display: block;

    .phoneLogo {
      display: block;
    }
  }
}

.phone {
  @include for-desktop-up {
    margin-left: 50px;
  }
}

.firstLine {
  display: flex;
  margin-bottom: 15px;
  align-items: center;

  div {
    @include for-desktop-up {
      display: none;
    }
  }
}

.contact {
  padding: 40px;
  padding-top: 60px;

  @include for-desktop-up {
    padding: 0;
  }
}

.imageSvg {
  display: none;

  @include for-desktop-up {
    display: block;
  }
}
