
    @import "config.scss";
 
.card {
  height: auto;
  background-color: var(--color-grey);
  // padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 25px;
  padding-bottom: 12px;

  @include for-desktop-up {
    padding-left: 12px;
    flex-direction: row;
    align-items: center;
  }

  .text {
    margin-left: 17px;
    width: 100%;
  }
}
