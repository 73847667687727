
    @import "config.scss";
 
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 35px;

  @include for-desktop-up {
    width: 25%;
    margin-bottom: 0;
  }
}

.content {
  display: flex;
  flex-direction: row;
  padding: 30px 20px;
}

.img {
  width: 100%;
  border-radius: 15px;
  height: 150px;
  position: relative;

  @include for-desktop-up {
    height: 250px;
  }
}

.text {
  margin-left: 17px;

  h2 {
    color: var(--color-primary);
    margin-bottom: clamp(10px, 2.5vw, 33px);
    margin-top: 0;
  }
}
