
    @import "config.scss";
 
.modalContainer {
  z-index: 100000;
  background-color: var(--color-grey);
  margin-inline: 5vw;
  border: 2px solid var(--color-white);
  border-radius: 25px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding-inline: 40px;
  padding-block: 45px;
  min-width: 300px;
  top: 25%;

  @include for-desktop-up {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
  }

  p {
    margin-bottom: 15px;
    text-align: center;
  }
}

.modal {
  background-color: #00000080;
  z-index: 10000;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}
