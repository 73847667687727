
    @import "config.scss";
 
.container {
  &:not(.unselectable):hover {
    background-color: var(--color-grey-lighter);

    & button {
      // color: var(--color-white);
    }
  }

  & button {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    width: 100%;
    color: black;
    padding: 15px;
    cursor: pointer;
    font-size: 0.875rem;
    text-align: left;

    > span {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    svg {
      margin-right: 10px;
    }
  }
}

.unselectable button {
  color: var(--color-grey-light);
  cursor: default;
  font-weight: 300;
}
