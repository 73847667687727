
    @import "config.scss";
 
.modalContainer {
  z-index: 100000;
  background-color: var(--color-grey);
  border: 2px solid var(--color-white);
  border-radius: 25px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 1rem;
  width: 40rem;
  max-width: 90%;
  gap: 1rem;

  @include for-desktop-up {
    padding-inline: 40px;
  }

  .logo {
    width: 12rem;
    height: 3rem;
    position: relative;
    margin-bottom: 20px;
  }
}

.modal {
  background-color: #00000080;
  z-index: 10000;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
