
    @import "config.scss";
 
.title {
  font-weight: bolder;
  padding: 30px 20px;
}

.container {
  display: flex;
  flex-direction: column;
  min-width: 100%;
}

.select {
  margin-top: 20px;

  & span {
    font-weight: 600;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cpForm {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1rem;
  column-gap: 2rem;
  align-items: end;
}
