
    @import "config.scss";
 
.wrapper {
  margin-top: 25px;
}

.partnerLogo {
  background-color: #282828;
  border-radius: 20px;
  margin-block: 20px;
  display: flex;
  justify-content: center;
  padding: 5px;
  @include for-desktop-up {
    width: 30%;
    min-height: 150px;
    align-items: center;
  }
}

.partners {
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  @include for-desktop-up {
    flex-direction: row;
    justify-content: space-around;
  }
}
