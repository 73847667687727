
    @import "config.scss";
 
.container {
  color: white;
  margin-bottom: 25px;
}

.info {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  height: 0;
}

.active {
  height: auto;
}

.faqs {
  padding: 20px 10px;
}

.dropdown {
  width: 100%;
  border: none;
  padding: 20px 10px;
  display: flex;
  color: white;
  font-weight: bold;
  font-size: 15px;
  background: #282828;
  height: 40px;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  div {
    transition: all 0.3s ease-in-out;
  }
}
