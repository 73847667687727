
    @import "config.scss";
 
.button {
  border-radius: var(--br-soft);
  cursor: pointer;
  display: flex;
  align-items: center;
  word-break: break-word;
  gap: 10px;
  border: 1px solid;
  height: 3rem;
  position: relative;

  .badge {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    top: -1px;
    right: -0.7rem;

    @include for-desktop-up {
      right: 0;
      top: -7px;
      width: 25px;
      height: 25px;
    }
  }

  &.primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-text-on-primary);
  }

  &.secondary {
    background-color: transparent;
    border-color: var(--color-primary);
    span {
      color: var(--color-primary) !important ;
    }
  }

  &.terciary {
    background-color: var(--color-grey);
    border-color: var(--color-grey);
    color: var(--color-text-on-terciary);
  }

  &.disabled {
    background-color: var(--color-grey);
    // border: none;
    border-color: var(--color-grey-lighter);
    color: var(--color-grey-lighter);
    cursor: not-allowed;
  }

  &.transparent {
    background-color: transparent;
    border-color: transparent;
    color: var(--color-text-on-transparent);
  }

  &.transparent-disabled {
    background-color: transparent;
    border-color: transparent;
    color: var(--color-text-on-transparent-disabled);
  }

  &.icon {
    background-color: transparent;
    border-color: transparent;
    padding: 0;
    height: 100%;
  }

  &.brHard {
    border-radius: var(--br-hard);
  }
}
