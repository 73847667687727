
    @import "config.scss";
 
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;

  //   position: fixed;
  //   top: 0;
  //   z-index: var(--z-spinner);
  //   width: 100%;
  //   height: 100%;
  //   background-color: var(--color-background-80);

  .image {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: 100%;

    img {
      border-radius: 50%;
    }
  }

  .border {
    animation: 2s linear infinite spinner;
    animation-play-state: inherit;
    border: 4px solid transparent;
    border-radius: 50%;
    background: linear-gradient(var(--color-primary), transparent) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    content: '';
    height: 60px;
    width: 60px;
    display: inline-block;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
