
    @import "config.scss";
 
.box {
  height: auto;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @include for-desktop-up {
    padding: 20px 50px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 12px;
  width: 100%;

  .text {
    margin-left: 17px;
    width: 100%;
  }
}

.brands {
  display: grid;
  justify-items: center;
  gap: 25px;
  align-items: center;

  @include for-desktop-up {
    grid-template-columns: 1fr 1fr;
  }
}
