
    @import "config.scss";
 
.menu {
  display: hidden;
  background-color: #121212;
  position: fixed;
  transform: translateX(-100%);
  transition: transform 0.4s;
  overflow-x: hidden;
  z-index: var(--z-modal);
  padding: 30px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.open {
  animation: 0.2s linear 0s forwards openAnimation;
  display: block;
}

.close {
  animation: 0.2s linear 0s forwards closeAnimation;
}

@keyframes openAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes closeAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.container .section:nth-child(2) {
  margin-top: 2rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 3rem;
}

.stores {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 200px;
  // gap: 1.5rem;
}
