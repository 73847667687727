
    @import "config.scss";
 
.storeBranchList {
  .item {
    margin: 15px;
    // @extend %subtitle-2;
    color: var(--color-white);
  }
  .title {
    font-weight: 400;
    text-align: left;
    margin-bottom: 10px;

    .chip {
      width: fit-content;
      background-color: #ffd100;
      color: black;
      border-radius: 15px;
      padding: 5px;
      margin-bottom: 10px;
    }

    .city {
      background-color: #00b1d3;
    }
  }

  span {
    display: flex;
    text-align: left;
  }
}

.button {
  border: thin solid var(--color-primary);
  background-color: transparent;
  cursor: pointer;
  margin: 10px;
  border-radius: 15px;
}

.button.active {
  border-left: 5px solid var(--color-primary);
  color: var(--color-primary);
}

.ccitem {
  display: flex;
  border: thin solid var(--color-primary);
  border-radius: 15px;
  padding: 20px;
  justify-content: space-between;
}
.buttonSelector {
  display: flex;
  width: 40%;
  justify-content: space-around;
}
