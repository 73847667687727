
    @import "config.scss";
 
.card {
  width: 100%;
  height: auto;
  background-color: var(--color-lightgrey);
  padding: 2vw;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;

  a {
    width: 100%;
  }

  @include for-desktop-up {
    max-width: none;
    min-width: 200px;
    padding: 1vw;
  }

  .layout {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .img {
      position: relative;
      width: clamp(78px, 20vw, 8rem);
      height: clamp(78px, 20vw, 8rem);

      @include for-desktop-up {
        width: 10vw;
        height: 10vw;
      }
    }

    .texts {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      gap: clamp(5px, 3.5vw, 60px);
      align-items: center;
      padding-left: clamp(20px, 2vw, 40px);
    }
  }
}

.logo {
  margin-top: 26px;
}

.arrows {
  position: absolute;
  right: 0;
  bottom: 15px;

  @include for-desktop-up {
    bottom: 5px;
  }
}
