
    @import "config.scss";
 
.container {
  color: white;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding-inline: 30px;
  min-height: 190px;
  text-align: center;
  gap: 30px;
}
