
    @import "config.scss";
 
.container {
  margin-bottom: 1rem;
  width: 100%;
}

.list {
  display: flex;
  gap: 1rem;
  width: 100%;
}
