
    @import "config.scss";
 
.container {
  padding: 1rem;
  border-radius: var(--br-hard);
  height: 100%;

  @include for-desktop-up {
    padding: 50px;
  }
}
