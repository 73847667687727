
    @import "config.scss";
 
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  height: 176px;
  overflow: hidden;

  .image {
    display: flex;
    align-items: center;
    margin-inline: 50px;

    img {
      border-radius: 15px;
    }
  }

  .imageMobile {
    display: none;
  }
}

.newsletter {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  max-width: 700px;

  .imageSvg {
    position: absolute;
  }

  .form {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    margin-top: 20px;
  }

  .title {
    font-weight: 800;
    text-align: right;
    z-index: var(--z-content);
  }

  .subtitle {
    font-size: 0.9rem;
    text-align: right;
    z-index: var(--z-content);
    padding: 1.5rem;
  }
}

.subscribeButton {
  position: absolute;
  right: -3px;
  top: 0;
  height: 100%;
  font-size: 10px;
}

.subscribe {
  transition: all 0.09s linear;
  border: 1px solid var(--color-primary);

  &:hover {
    background-color: var(--color-grey);
    span {
      color: white !important;
    }
    div {
      div {
        div {
          background: linear-gradient(var(--color-primary), transparent)
            border-box border-box !important;
        }
      }
    }
  }
}
.containerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .containerTextAlert {
    position: absolute;
    bottom: 5px;
    right: 35%;
    display: flex;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
      sans-serif;
  }

  .containerFormActions {
    display: flex;
    align-items: center;
    position: relative;
    left: 0;

    input {
      padding: 10px;
      border-radius: 40px;
      width: 550px;
    }

    .button {
      z-index: var(--z-content-top);
      border-radius: 40px;
      box-shadow: 10px 10px 16px -5px rgba(0, 0, 0, 0.75);
      margin-top: 4px;
      position: absolute;
      right: 0;
    }
  }
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;

    .image {
      display: none;
    }

    .imageMobile {
      display: block;
    }
  }
}

@media (max-width: 1000px) {
  .newsletter {
    display: flex;
    flex-direction: column;

    .image {
      display: none;
    }

    .textNewsletter {
      position: relative;
      top: 10px;
      margin-right: 0;
      margin-bottom: 5px;
    }
  }

  .containerFormActions {
    .input {
      max-width: 350px;
    }

    .button {
      position: absolute;
      right: 2%;
    }
  }

  .containerFormActions input {
    max-width: 340px;
  }
}

.callResult {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 45px;
}
