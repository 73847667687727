
    @import "config.scss";
 
.container {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--br-medium);
  margin-bottom: 0px;
  scroll-snap-align: center;
  position: relative;
  overflow: hidden;
  padding: 10%;
}

.title {
  z-index: 2;
  font-size: 1.5rem;
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;

  img {
    width: 100%;
    height: 100%;
  }
}
