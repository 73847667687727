
    @import "config.scss";
 
.wrapper {
  background-color: var(--color-black);
  padding-block: 27px;
  padding-inline: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .copy {
    @include for-desktop-up {
      width: 35%;
    }
  }

  .divider {
    display: none;
    @include for-desktop-up {
      display: block;
    }
  }
  @include for-desktop-up {
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}

.img {
  width: 70px;
  height: 50px;
  position: relative;
}

.button {
  border-radius: 15px;
  border: 1px solid var(--color-primary);
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  height: max-content;
  max-width: 300px;
  padding: 20px;

  @include for-desktop-up {
    padding-right: 25px;
    margin-bottom: 0;
  }
}

.safeLogos {
  // margin-block: 20px;
  // margin-inline: 10px;
  display: flex;
}

.event {
  width: 280px;
  height: 80px;
  position: relative;
}

.flexWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-block: 2rem;
  gap: 2rem;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  .copy {
    width: 100%;
    flex: 1;
  }
  .title {
    font-size: 12px;
    color: white;
    font-weight: 200;
  }
}
