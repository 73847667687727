
    @import "config.scss";
 
.container {
  background-color: var(--color-white-absolute);
  border-radius: 50px;
  width: 3rem;
  height: 3rem;
  position: relative;
  padding: 0.8rem;
}

.tooltip {
  display: flex;
  position: absolute;
  top: -4rem;
  right: 0;
  gap: 0.6rem;
}

.center svg {
  display: flex;
}

.show {
  display: flex;
}

.hide {
  display: none;
}

.fadeOut {
  animation: fadeOut 0.2s ease-in-out;
}

.fadeIn {
  animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
