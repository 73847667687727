
    @import "config.scss";
 
.faqTitle {
  border: none;
  width: 100%;
  background-color: transparent;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 12px;
}

.titleSection {
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: bold;
}

.faqInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  color: var(--color-white);
  font-size: 13px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  height: 0px;
  padding: 0px 30px;
  a {
    color: var(--color-primary);
  }

  h1 {
    padding-bottom: 35px;
  }

  p {
    padding-bottom: 20px;
  }
}

.icon {
  position: relative;
}

.vertical {
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  align-content: center;
}

.horizontal {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  align-content: center;
  opacity: 1;
}

.brandsItems {
  display: flex;
  width: 100px;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: flex-start;
  margin-bottom: 15px;

  li {
    color: var(--color-primary);
    width: min-content;
    text-align: center;
  }
}

.brandWrapper {
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;

  a {
    width: 30%;
  }
}
