
    @import "config.scss";
 
.chip {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-primary);
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  gap: 0.2rem;
}
.simpleChip {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
