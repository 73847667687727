
    @import "config.scss";
 
.categoriesContainer {
  display: flex;
  justify-items: center;
  max-width: 100vw;
  gap: 4vw;
  padding-block: 1rem;
  justify-content: center;
}

.brandsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(183px, 1fr));
  justify-items: center;
  max-width: 100vw;
  gap: 0.5rem;
  padding-block: 1rem;

  @include for-desktop-up {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
