
    @import "config.scss";
 
.card {
  width: 100%;
  height: auto;
  background-color: var(--color-grey);
  padding: 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;

  &:hover {
    padding: 13px; // queda en 13 para considerar el tamaño del border
  }

  a {
    width: 100%;
  }

  @include for-desktop-up {
    max-width: 400px;
    min-width: 200px;
  }

  .layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img {
      position: relative;
      width: 100%;
      height: 120px;

      @include for-desktop-up {
        width: 100%;
        height: 150px; // 220
      }

      .flow {
        position: absolute;
        bottom: -21px;
        right: 0;
        z-index: var(--z-content);

        svg {
          transform: scale(0.7);
        }

        @include for-desktop-up {
          right: 11px;

          svg {
            transform: scale(1);
          }
        }
      }
    }
  }
}
.card:hover {
  border: 2px solid;
  border-radius: 15px;
}

.logo {
  margin-top: 26px;
}

.arrows {
  position: absolute;
  right: 0;
  bottom: 15px;

  @include for-desktop-up {
    bottom: 5px;
  }
}
