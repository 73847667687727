
    @import "config.scss";
 
.container {
  padding: 1.5rem;
  width: 75%;

  @include for-desktop-up {
    width: 60%;
  }
}
