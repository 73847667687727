
    @import "config.scss";
 
.selectButton {
  width: 100%;
  border: 1px var(--color-primary) solid;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: transparent;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  @include for-desktop-up {
    width: 75%;
    align-self: center;
  }

  svg {
    color: var(--color-primary);
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    @include for-desktop-up {
      flex-direction: row;
      align-items: center;
      width: 95%;
    }
  }

  h3 {
    font-size: 16px;
    margin-right: 50px;
    text-align: initial;
    width: 100%;
  }

  span {
    font-size: 11px;
  }
}

.icon {
  display: none;

  @include for-desktop-up {
    display: flex;
    margin-right: 5%;
  }
}
